import React, { Component, useEffect, useMemo, useState } from 'react'
import jwtDecode from 'jwt-decode'
import Reflux from 'reflux'
import styled from 'styled-components'
import Alert from 'react-s-alert'
import Loadable from 'react-loadable'
import { Route, Redirect, Switch, useLocation, useHistory } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-s-alert/dist/s-alert-default.css'
import 'react-s-alert/dist/s-alert-css-effects/slide.css'
import 'react-toastify/dist/ReactToastify.css'
import 'rc-tooltip/assets/bootstrap.css'

import { AuthActions } from '/stores/AuthStore'
import { NotificationActions } from '/stores/NotificationStore'
import AnimatedPatternBG from './components/AnimatedPatternBG'
import CustomAlert from '/components/CustomAlert'

import ThemeController from './ThemeController'
import { GlobalStyle } from './ThemeStyles'
import ChooseLogin from 'screens/ChooseLogin'
import VerifyUser from 'screens/VerifyUser'
import FinishSignup from 'screens/FinishSignup'
import MagicLink from 'screens/MagicLink'
import Presentation from 'screens/Presentation'
import ResetPassword from './screens/ResetPassword'
import { isMobile, isMobileOrNotIpad } from './calling/_helpers'

import { Firebase } from './helpers/firebase';

import { getFirestore, doc, onSnapshot } from 'firebase/firestore';

import * as Sentry from '@sentry/react'

import { useIdleTimer } from 'react-idle-timer'


require('events').EventEmitter.defaultMaxListeners = 100

const TimeTravelScreen = Loadable({
  loader: () => import('screens/TimeTravelScreen'),
  loading: () => <div />,
})

const TimeTravel = Loadable({
  loader: () => import('screens/TimeTravel2.0'),
  loading: () => <div />,
})

import StoreInitializer from '/components/StoreInitializer'
import NotSupported from './NotSupported'
import { Upgrade } from './screens/Upgrade'
import RedirectToRoom from './screens/redirect'
import SSOComponent from './screens/sso'
import AppStateProvider from './contexts/AppStateProvider'
import ForgotPassword from './screens/ForgotPassword'
import SignUp from './screens/Signup'
import Login from './screens/Login'
import TokenExpired from './components/TokenExpired'
import MobileView from './screens/MobileView'
import Setup from './screens/Meeting2.0/Setup'
import Expired from './screens/Meeting2.0/Setup/Expired'
import JoinExpired from './screens/Meeting2.0/Setup/JoinExpired'
import UnderMaintenance from './screens/UnderMaintenance'
import CalendarComponent from './screens/Calendar'
import WhatsNew from './screens/Meeting/Modals/WhatsNew'
import Onboarding from './screens/Onboarding'

let is_electron = false
var userAgent = navigator.userAgent.toLowerCase()
if (userAgent.indexOf(' electron/') > -1) {
  is_electron = true
}

if (!is_electron) {
  if ('serviceWorker' in navigator && 'PushManager' in window) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        registration.unregister()
      }
    })
    const sw_script = '/notification-sw.js'
    navigator.serviceWorker
      .register(sw_script)
      .then((r) => {
        //console.log('SW Cache Update Complete')
        window.notification_worker = r
        r.onupdatefound = () => {
          const installingWorker = r.installing
          installingWorker.onstatechange = () => {
            switch (installingWorker.state) {
              case 'installed':
                if (navigator.serviceWorker.controller) {
                  NotificationActions.UpdateAvailable()
                }
                break
              default:
                return
            }
          }
        }
      })
      .catch((e) => console.error(e))
  }
}

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast--default {
    border-radius: 10px;
  }

  .Toastify__close-button--default {
    display: none;
  }

  &.Toastify__toast-container {
    width: 330px;
  }

  &.Toastify__toast-container--top-right {
    transform: translateY(80px);
    z-index: 1001;
  }

  .Toastify__progress-bar--default {
    top: 0%;
  }

  .toastProgressL.Toastify__progress-bar--default {
    background: #f89809;
  }

  .toastProgressD.Toastify__progress-bar--default {
    background: #008bff;
  }

  .color-success {
    color: #00bf9c;
  }

  .bg-success-opaque {
    background: rgba(0, 191, 156, 0.2);
  }

  .color-error {
    color: #ff3c3c;
  }

  .bg-error-opaque {
    background: rgba(255, 60, 60, 0.2);
  }

  .Toastify__toast--default.toastL {
    background: #ffffff;
    color: #363b45;
    .color-primary {
      color: #f89809;
    }
    .bg-primary {
      background: #f89809;
    }
    .bg-default {
      background: #363b45;
    }

    .svg-fill{
      path { fill: #363b45; }
    }

    .primary-fill {
      fill: #f89809 !important;
    }
    .svg-fill-primary {
      path {
        fill: #f89809;
      }
    }
    .bg-primary-opaque {
      background: rgba(255, 153, 0, 0.2);
    }
  }

  .Toastify__toast--default.toastD {
    background: #272732;
    color: white;
    .color-primary {
      color: #008bff;
    }
    .bg-primary {
      background: #008bff;
    }
    .bg-default {
      background: #414052;
    }
    .primary-fill {
      fill: #008BFF !important;
    }
    .svg-fill{
      path { fill: white; }
    }
    .svg-fill-primary {
      path {
        fill: #008bff;
      }
    }
    .bg-primary-opaque {
      background: rgba(0, 139, 255, 0.2);
    }
  }

  .Toastify__toast--default.topM {
    /* margin-top: 80px; */
  }

  .Toastify__toast-body {
    white-space: pre-line;
  }

  #meetingLinkCopied {
    min-height: 40px;
  }
`

const App = () => {
  let color_scheme = 'Dark'
  const [expired, setExpired] = useState(false)
  let location = useLocation()
  let history = useHistory()
  const [underMaintenance, setUnderMaintenance] = useState(false)
  const [reason, setReason] = useState('')
  const [maintenanceTitle, setMaintenanceTitle] = useState('')

  const db = getFirestore(Firebase.init());

  React.useEffect(() => {
    AuthActions.SetJwt()
    window.addEventListener('blur', () => {
      toast.dismiss('lockMeetingNotif')
      toast.dismiss('transferHostNotif')
      toast.dismiss('leftMeetingNotif')
      toast.dismiss('joinMeetingNotif')
      toast.dismiss('startMeetingNotif')
    })
    return () => {
      window.removeEventListener('blur', () => {
        toast.dismiss('lockMeetingNotif')
        toast.dismiss('transferHostNotif')
        toast.dismiss('leftMeetingNotif')
        toast.dismiss('joinMeetingNotif')
        toast.dismiss('startMeetingNotif')
      })
    }
  }, [])

  const handleMaintenance = async (newDB) => {
    onSnapshot(doc(newDB, "grapl", `${process.env.REACT_APP_ENV_SERVER}_maintenance`.toUpperCase()), (docs) => {
      const { status } = Reflux.GlobalState.calling
      const data = docs.data()
      setUnderMaintenance(data?.web || false)

      if(data?.web) {
        setReason(data?.description || '')
        setMaintenanceTitle(data?.title || '')
      }
      if(status === 'CONNECTED' && data?.maintenance) {
        window?.leaveMeeting()
      }
    })
  }

  useEffect(() => {
    handleMaintenance(db)
  }, [db])

  useEffect(() => {
    function call() {
      const decoded = localStorage.getItem('token') ? jwtDecode(localStorage.getItem('token')) : null
      const validation = ['expired', 'login', 'choose-login', 'fullsignup', 'magic_link', 'verify', 'confirm', 'forgot-password', 'reset']

      const { status } = Reflux.GlobalState.calling
      const { finishedCheckMeeting } = Reflux.GlobalState.auth

      if(status === 'CONNECTED') {
        setExpired(false)
      } else if (
        (!localStorage.getItem('token') && location.pathname === '/create-or-join') ||
        ((localStorage.getItem('token') && decoded && decoded.exp && Math.floor(Date.now() / 1000) > decoded.exp)) ||
        (!validation.some(v => location.pathname.includes(v)) && !localStorage.getItem('token') && !!finishedCheckMeeting)
      ) {
        setExpired(true)
      }
      if (validation.some(v => location.pathname.includes(v)) && !localStorage.getItem('token')) {
        setExpired(false)
      }
    }
    call()
    const checkInterval = setInterval(() => {
      call()
    }, 10000)
    return () => {
      clearInterval(checkInterval)
    }
  }, [location.pathname])

  setTimeout(() => {
    color_scheme = Reflux.GlobalState.theming.color_scheme
    // //console.log(color_scheme)
  }, 0)

  const handleOnIdle = event => {
    // console.log('user is idle', event)
    // console.log('last active', getLastActiveTime())
    const { status } = Reflux.GlobalState.calling
    if (status !== "CONNECTED") {
      if (localStorage.getItem('token')) {
        localStorage.removeItem('token')
      }
      if (localStorage.getItem('audio_input_device_id')) {
        localStorage.removeItem('audio_input_device_id')
      }
      if (localStorage.getItem('video_input_device_id')) {
        localStorage.removeItem('video_input_device_id')
      }
      if (localStorage.getItem('audio_output_device_id')) {
        localStorage.removeItem('audio_output_device_id')
      }
    }
  }

  const handleOnActive = event => {
    // console.log('user is active', event)
    // console.log('time remaining', getRemainingTime())
  }

  const handleOnAction = event => {
    // console.log('user did something', event)
  }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 60 * 48,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
    crossTab: {
      emitOnAllTabs: true
    }
  })

  let browserName = ''

  if (userAgent.match(/edg/i)) {
    browserName = 'edge'
  } else if (userAgent.match(/opr\//i)) {
    browserName = 'opera'
  } else if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = 'chrome'
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = 'firefox'
  } else if (userAgent.match(/safari/i)) {
    browserName = 'safari'
  } else {
    browserName = 'No browser detection'
  }

  if(underMaintenance) {
    return (
      <React.Fragment>
        <StoreInitializer />
        <GlobalStyle />
        <AppStateProvider>
          <ThemeController>
            <UnderMaintenance reason={reason} title={maintenanceTitle} />
          </ThemeController>
        </AppStateProvider>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <StoreInitializer />
      <GlobalStyle />
      <AppStateProvider>
        <ThemeController>
          <MainContainer>
            <AnimatedPatternBG />
            <StyledToastContainer pauseOnFocusLoss={false} />
            {isMobileOrNotIpad ? <MobileView /> : browserName !== 'chrome' && browserName !== 'safari' ? (
              <NotSupported />
            ) : (
              <React.Fragment>
                {expired && <TokenExpired />}
                <RouteContainer>
                  <Switch>
                    <Route
                      path="/magic_link/:token_code"
                      component={MagicLink}
                      sensitive
                    />
                    <Route path="/sso/:token" component={SSOComponent} />
                    <Route
                      sensitive
                      path="/:slug1*:slug2([^a-z0-9-_/#]):slug3*"
                      render={(props) => (
                        <Redirect
                          to={`${props.location.pathname
                            .toLowerCase()
                            .replace(' ', '-')
                            .replace(/[^a-z0-9-_/]/g, '')}`}
                        />
                      )}
                    />
                    {/* <Route
                    sensitive
                    path="/:slug1([^a-zA-Z]):slug2*"
                    render={(props) => (
                      <Redirect to={`/m${props.location.pathname.slice(1)}`} />
                    )}
                  /> */}
                    <Route path="/login/:meetingName?" component={Login} />
                    <Route
                      path="/choose-login/:meetingName"
                      component={ChooseLogin}
                    />
                    <Route
                      path="/expired/:meetingName"
                      component={JoinExpired}
                    />
                    {/* <Route path="/signup/:meetingName?" component={Signup} /> */}
                    <Route path="/fullsignup/:meetingName?" component={SignUp} />
                    <Route path="/signupwithspace/:type/:sharedspaceid/:inviteid/:folderid?" component={SignUp} />
                    <Route path="/verify/:token_code" component={VerifyUser} />
                    <Route path="/confirm/:token_code" component={FinishSignup} />
                    <Route
                      path="/time-travel2/:meetingName"
                      component={TimeTravelScreen}
                    />
                    <Route
                      path="/time-travel/:meetingName"
                      component={TimeTravel}
                    />
                    <Route
                      path="/meeting/:meetingName"
                      component={({ match }) => (
                        <Redirect to={`/${match.params.meetingName}`} />
                      )}
                    />
                    <Route
                      exact
                      path="/forgot-password"
                      component={ForgotPassword}
                    />
                    <Route
                      exact
                      path="/reset/:token_code"
                      component={ResetPassword}
                    />
                    <Route path="/upgrade" component={Upgrade} />
                    <Route path="/redirect" component={RedirectToRoom} />
                    <Route path="/onboarding" component={Onboarding} />
                    <Route path="/:meetingName?" component={Presentation} />
                  </Switch>
                </RouteContainer>
              </React.Fragment>
            )}
            <Alert contentTemplate={CustomAlert} />

            <CalendarComponent />
          </MainContainer>
        </ThemeController>
      </AppStateProvider>
    </React.Fragment>
  )
}

export default Sentry.withProfiler(App)

const RouteContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
`

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
