import React from 'react'
import styled from 'styled-components'
import screenfull from 'screenfull'
import Reflux from 'reflux'
import { withRouter } from 'react-router-dom'
import { MdEdit } from '@react-icons/all-files/md/MdEdit'
import { FaCamera } from '@react-icons/all-files/fa/FaCamera'
import { FiGrid as IntegrationsIcon } from '@react-icons/all-files/fi/FiGrid'
import { FiCreditCard as BillingIcon } from '@react-icons/all-files/fi/FiCreditCard'
import { FiAward as UpgradeIcon } from '@react-icons/all-files/fi/FiAward'
import { FiLock as PasswordIcon } from '@react-icons/all-files/fi/FiLock'
import { FiLogOut as LogoutIcon } from '@react-icons/all-files/fi/FiLogOut'
import { AiOutlineUser as UserIcon } from '@react-icons/all-files/ai/AiOutlineUser'

import Sagas from '/helpers/Sagas'
import { MainStore } from '../../../../stores/MainStore'
import { ModalActions } from '../../../../stores/ModalStore'
import { VariableCallingStore } from '../../../../stores/VariableCallingStore'
import LoopApi from '/helpers/LoopApi'
import { AuthStore } from '../../../../stores/AuthStore'

import { Slide, toast } from 'react-toastify'
import { version } from '../../../../../package.json';
import { CallingInstanceState } from '../../../../calling/types'
import { AuthActions } from '../../../../stores/AuthStore'
import Toggle from '../../../../components/Toggle'
import { ThemingStore } from '../../../../stores/ThemingStore'
import { MainActions } from '../../../../stores/MainStore'
import ReactVisibilitySensor from 'react-visibility-sensor'
import { Img } from 'react-image'
import Loader from '../../../../components/Loader'
import Grapl from '../../../../assets/Icon-SVG.svg'
const IconSvg = <img src={Grapl} alt="Grapl"/>
class MeetingDropdown extends Reflux.Component {
	constructor(props) {
		super(props)
		this.stores = [MainStore, AuthStore, VariableCallingStore, ThemingStore]
		this.storeKeys = ['db_meeting', 'jwt', 'status', 'color_scheme']
		this.state = {
			db_meeting: { settings: {} }, jwt: { data: {} },
			enableEdit: false,
			username: '',
			status: '',
			color_scheme: 'Light',
            isFullscreen: window.matchMedia('(display-mode: fullscreen)').matches,
            limits: {},
		}

		this.input = null

		this.InviteMembers = this.InviteMembers.bind(this)
		this.ToggleTranscribe = this.ToggleTranscribe.bind(this)
		this.ToggleFullscreen = this.ToggleFullscreen.bind(this)
		this.TogglePublic = this.TogglePublic.bind(this)
		this.WidgetExposure = this.WidgetExposure.bind(this)
		this.ToggleRecord = this.ToggleRecord.bind(this)
		this.CreateNewWorkspace = this.CreateNewWorkspace.bind(this)
		this.ToggleKnocking = this.ToggleKnocking.bind(this)
		this.Logout = this.Logout.bind(this)
		this.SetPassword = this.SetPassword.bind(this)
		this.SetAvatar = this.SetAvatar.bind(this)
		this.SetSources = this.SetSources.bind(this)
		this.OpenIntegrations = this.OpenIntegrations.bind(this)
		this.OpenUpdateProfile = this.OpenUpdateProfile.bind(this)
		this.ChangeBotSettings = this.ChangeBotSettings.bind(this)
		this.SetMeetingAvatar = this.SetMeetingAvatar.bind(this)
		this.LeaveMeeting = this.LeaveMeeting.bind(this)
		this.connectToMeeting = this.connectToMeeting.bind(this)
		this.ChangePassword = this.ChangePassword.bind(this)
		this.handleEnableEdit = this.handleEnableEdit.bind(this)
		this.handleKeyPress = this.handleKeyPress.bind(this)
		this.handleChange = this.handleChange.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
        this.ToggleSubscription = this.ToggleSubscription.bind(this)
        this.ToggleBillings = this.ToggleBillings.bind(this)
	}

	ToggleRecord() {
		Sagas.changeSetting('record', !this.state.db_meeting.settings.record)
	}

	OpenIntegrations() {
		this.props.onClose && this.props.onClose()
		ModalActions.SetModal('Integrations')
	}

	OpenUpdateProfile() {
		this.props.onClose && this.props.onClose()
		ModalActions.SetModal('UserProfile')
	}

	InviteMembers() {
		this.props.onClose && this.props.onClose()
		ModalActions.SetModal('PublicLink')
	}

	SetPassword() {
		this.props.onClose && this.props.onClose()
		ModalActions.SetModal('ChangeMeetingPassword')
	}

	SetAvatar() {
		this.props.onClose && this.props.onClose()
		ModalActions.SetModal('SetUserAvatar')
	}

	SetSources() {
		this.props.onClose && this.props.onClose()
		ModalActions.SetModal('SetSources')
	}

	SetMeetingAvatar() {
		this.props.onClose && this.props.onClose()
		ModalActions.SetModal('SetMeetingAvatar')
	}

	WidgetExposure() {
		this.props.onClose && this.props.onClose()
		ModalActions.SetModal('WidgetExposure')
	}

	ChangeBotSettings() {
		this.props.onClose && this.props.onClose()
		ModalActions.SetModal('ChangeBotSettings')
	}

	ChangePassword() {
		this.props.onClose && this.props.onClose()
		ModalActions.SetModal('ChangePassword')
	}

	ToggleFullscreen() {
		if (screenfull.isEnabled) {
			screenfull.toggle()
		}
	}

    ToggleSubscription() {
		this.props.onClose && this.props.onClose()
		ModalActions.SetModal('Subscription')
    }

    ToggleBillings() {
		this.props.onClose && this.props.onClose()
		ModalActions.SetModal('Billings')
    }

	ToggleTranscribe() {
		Sagas.changeSetting(
			'transcribe',
			!this.state.db_meeting.settings.transcribe
		)
	}

	TogglePublic() {
		Sagas.changeSetting('is_public', !this.state.db_meeting.settings.is_public)
	}

	ToggleKnocking() {
		Sagas.changeSetting(
			'allow_knocking',
			!this.state.db_meeting.settings.allow_knocking
		)
	}

	CreateNewWorkspace() {
		this.props.onClose && this.props.onClose()
		this.props.history.push('/create-or-join#create')
	}

	LeaveMeeting() {
		this.props.onClose && this.props.onClose()
		ModalActions.SetModal('LeaveMeeting')
	}

	connectToMeeting() {
		this.props.onClose && this.props.onClose()
		this.props.history.push('/create-or-join')
	}

	Logout() {
		const isInCall = this.state.status === CallingInstanceState.Connected
		if(isInCall) {
			ModalActions.SetModal('ConfirmLogout')
		} else {
			localStorage.removeItem('token')
			sessionStorage.removeItem('roomsAllowed')
			localStorage.removeItem('recentRooms')
			if(localStorage.getItem('audio_input_device_id')) {
				localStorage.removeItem('audio_input_device_id')
			}
			if(localStorage.getItem('video_input_device_id')) {
				localStorage.removeItem('video_input_device_id')
			}
			if(localStorage.getItem('audio_output_device_id')) {
				localStorage.removeItem('audio_output_device_id')
			}
			localStorage.removeItem('skipped_tour');
			localStorage.removeItem('fromonboarding');

			this.props.history.push('/login')
		}
	}

	handleEnableEdit() {
		this.setState((prevState) => ({ enableEdit: !prevState.enableEdit }), () => {
			if (this.state.enableEdit && this.input) {
				this.input.focus()
			}
		})
	}

	handleKeyPress(e) {
		if (e.key === 'Enter') {
			// Submit
			this.handleSubmit()
			this.handleEnableEdit()
		}
		if (e.key === 'Escape') {
			this.handleEnableEdit()
		}
	}

	async handleSubmit() {
		try {
			const token = await LoopApi(null, 'SetName', { username: this.state.username })
			if (!!token && token.token) {
				localStorage.token = token.token
				AuthActions.SetJwt(token.token)
				// location.reload(true)
			}
            if(token.error) toast(token.msg.errors.username.message || "Error updating information.")
		} catch (err) {
			console.error(err)
		}
	}

	handleChange(e) {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	async componentDidMount() {
        const limits = await LoopApi(null, 'StripeGetRoomOwnerLimits');
		this.setState({ username: this.state.jwt.data.username, limits })
		window.matchMedia('(display-mode: fullscreen)').addListener(this.checkFullScreen)
	}

	componentDidUpdate(prevProps, prevState) {
        console.log(this.state.limits)
		if(prevState.jwt.data.username !== this.state.jwt.data.username) {
			this.setState({ username: this.state.jwt.data.username })
		}
	}

	componentWillUnmount() {
		window.matchMedia('(display-mode: fullscreen)').removeListener(this.checkFullScreen)
	}

	checkFullScreen = (e) => {
		this.setState({ isFullscreen: e.matches })
	}

	render() {
		const { data = {} } = this.state.jwt
		return (
            <Container className='topbar' data-tour='tour2-manageprofile2'>
                {!this.props.setup && [
                    <MiniHeader key='MiniHeader'>Room:</MiniHeader>,
                    <Item key='ChangeBotSettings' onClick={this.ChangeBotSettings}>
                        Assistant Settings
                    </Item>,
                    <Item key='InviteMembers' onClick={this.InviteMembers}>
                        Invite Members
                    </Item>,
                    <Item key='SetMeetingAvatar' onClick={this.SetMeetingAvatar}>
                        Set Meeting Icon
                    </Item>,
                    <Item key='WidgetExposure' onClick={this.WidgetExposure}>
                        Sync Exposure
                    </Item>,
                    <Item key='ToggleTranscribe' onClick={this.ToggleTranscribe}>
                        Transcribe Calls {this.state.db_meeting.settings.transcribe ? 'ON' : 'OFF'}
                    </Item>,
                    <Item key='ToggleRecord' onClick={this.ToggleRecord}>
                        Record Calls {this.state.db_meeting.settings.record ? 'ON' : 'OFF'}
                    </Item>,
                    <Item key='TogglePublic' onClick={this.TogglePublic}>
                        Public Room {this.state.db_meeting.settings.is_public ? 'ON' : 'OFF'}
                    </Item>,
                    !this.state.db_meeting.settings.is_public && (
                        <Item key='ToggleKnocking' onClick={this.ToggleKnocking}>
                            Allow Knocking {this.state.db_meeting.settings.allow_knocking ? 'ON' : 'OFF'}
                        </Item>
                    ),
                    !this.state.db_meeting.ghost_mode && this.state.db_meeting.settings.is_public && (
                        <Item key='SetPassword' onClick={this.SetPassword}>
                            {this.state.db_meeting.password ? 'Change Password' : 'Set Password'}
                        </Item>
                    ),
                    <Item key='LeaveMeeting' color='red' onClick={this.LeaveMeeting}>
                        Leave Room Forever
                    </Item>,
                ]}

                {/* <SectionHeader>Profile</SectionHeader> */}

                <UserInfo>
                    <div className='camera-wrapper'>

						<ReactVisibilitySensor>
							<PreviewImage 
								key={this.state.file || data.avatar_url || `https://ui-avatars.com/api/?name=${data.username || data.name || ''}&format=svg&background=${data.color || 'random'}&color=fefefe` || 'https://grapl-storage.s3.ap-southeast-1.amazonaws.com/Grapl.svg'} 
								src={[this.state.file, data.avatar_url, `https://ui-avatars.com/api/?name=${data.username || data.name || ''}&format=svg&background=${data.color || 'random'}&color=fefefe`, 'https://grapl-storage.s3.ap-southeast-1.amazonaws.com/Grapl.svg']} 
								loader={<Loader circle containerHeight="100px" containerWidth="100px" />} 
								alt={data.username || data.name} 
								unloader={IconSvg} />
						</ReactVisibilitySensor>
                        {/* <PreviewImage
                            src={
                                this.state.file ||
                                data.avatar_url ||
                                `https://ui-avatars.com/api/?name=${data.username}&format=svg&background=${
                                    data.color || 'random'
                                }&color=fefefe`
                            }
                            alt='profile_img'
                        /> */}
                        <div className='camera' onClick={this.SetAvatar}>
                            <FaCamera />
                        </div>
                    </div>
                    <div>
                        {this.state.enableEdit ? (
                            <input
                                ref={(el) => (this.input = el)}
                                name='username'
                                className='username-input container focus'
                                type='text'
                                value={this.state.username}
                                onChange={this.handleChange}
                                onBlur={this.handleEnableEdit}
                                onKeyPress={this.handleKeyPress}
                                maxLength={50}
                            />
                        ) : (
                            <div className='username'>
                                <span>{data.username || ''}</span>
                                {/* <MdEdit className="edit" /> */}
                            </div>
                        )}
                    </div>
                    <div className='user-email'>{data.email || ''}</div>
                </UserInfo>
                <NavigationWrapper>
                    <Separator className='border-light' />
                    {/* <Item onClick={this.SetAvatar}>Set Your Profile Icon</Item> */}
                    <Item onClick={this.OpenUpdateProfile}>
                        <div className='icon'>
                            <UserIcon size={20} />
                        </div>
                        <div className='text'>{data.type === 'guest' ? 'Register Account' : 'Update Profile'}</div>
                    </Item>
                    {/* <Item onClick={this.InviteMembers}>
						Invite Users
					</Item> */}
                    {/* <Item onClick={this.SetSources}>Video/Audio Sources</Item> */}
                    <Item onClick={this.OpenIntegrations}>
                        <div className='icon'>
                            <IntegrationsIcon size={20} />
                        </div>
                        <div className='text'>Integrations</div>
                    </Item>

                    {data.type === 'account' ? (
                        data.isOrgAdmin ? (
                            <Item
                                onClick={() =>
									window.open(
										`${
                                        process.env.REACT_APP_ADMIN_URL
                                    }/grapl/auto-login?token=${localStorage.getItem('token')}`, '_blank'
									)
                                }
                            >
                                <div className='icon'>
                                    <BillingIcon size={20} />
                                </div>
                                <div className='text'>Organization</div>
                            </Item>
                        ) : null
                    ) : null}
                    
                    {console.log('====>',data)}
                    {data.type === 'account' ? (
                        (data.isSuperAdmin || (data.role === 'grapl' || data.role === 'admin')) ? (
                            <Item
                                onClick={() =>
									window.open(`${
                                        process.env.REACT_APP_ADMIN_URL
                                    }/grapl/auto-login?token=${localStorage.getItem('token')}`, '_blank')
                                }
                            >
                                <div className='icon'>
                                    <BillingIcon size={20} />
                                </div>
                                <div className='text'>Admin</div>
                            </Item>
                        ) : null
                    ) : null}

                    {data.type === 'account' ? (
                         data.organizations.length === 0 || this.state.limits.hasSubscription ? (
                            <Item onClick={this.ToggleBillings}>
                                <div className='icon'>
                                    <BillingIcon size={20} />
                                </div>
                                <div className='text'>Billing</div>
                            </Item>
                        ) : null
                    ) : null}
                     

                    <Separator className='border-light' />
                    <Item className='nohovereffect'>
                        <div className='text center'>{this.state.color_scheme} Mode</div>
                        <div className='icon'>
                            <Toggle
                                checked={this.state.color_scheme === 'Light'}
                                onChange={(e) => MainActions.SetTheme(e.target.checked ? 'Light' : 'Dark')}
                            />
                        </div>
                    </Item>
                    <Item className='nohovereffect'>
                        <div className='text center'>{this.state.isFullscreen ? 'Exit' : ''} Fullscreen Mode</div>
                        <div className='icon'>
                            <Toggle
                                checked={this.state.isFullscreen}
                                onChange={() => {
                                    this.setState({ isFullscreen: !this.state.isFullscreen });
                                    screenfull && screenfull.toggle && screenfull.toggle();
                                }}
                            />
                        </div>
                    </Item>
                    {/* <Item>
						<Dropdowns key="keynotetopbarDropdowns" />
					</Item>
					{/* {screenfull.isEnabled && (
						<Item onClick={this.ToggleFullscreen}>Fullscreen mode</Item>
					)}
					<Item onClick={this.OpenIntegrations}>Integrations</Item> */}

                    <Separator className='border-light' />
                    <Item color='red' onClick={this.Logout}>
                        {/* <div className='icon'>
                            <LogoutIcon size={20} />
                        </div> */}
                        <div className='text'>Log out</div>
                    </Item>
                </NavigationWrapper>

                <div className='app-version'>v{version}</div>
            </Container>
        );
	}
}

const SectionHeader = styled.div`
	font-size: 16px;
	font-weight: 600;
	padding: 5px 24px;
`

const UserInfo = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 20px 0 10px;

	.username-input {
		margin: 5px 20px;
		padding: 10px;
		border: none;
		outline: none;
		border-radius: ${props => props.theme.buttonBorderRadius};
	}

	.camera-wrapper {
		position: relative;
		.camera {
			display: none;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			background: rgba(0, 0, 0, 0.5);
			border-radius: 50%;
			height: 100px;
			width: 100px;
			justify-content: center;
			align-items: center;
			font-size: 24px;
			color: rgba(255, 255, 255, 0.7);
			transition: 0.3s display ease-in-out;
		}

		&:hover {
			.camera {
				display: flex;
			}
		}
	}


	.username {
		display: flex;
		padding: 5px 20px;
		position: relative;
		align-items: center;
		.edit {
			display: none;
			position: absolute;
			right: 0;
		}

		span {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			max-width: 250px;
		}

		&:hover {
			.edit {
				display: block;
			}
		}
	}

	.user-email {
		font-size: 12px;
		opacity: 0.5;
		font-weight: 400;
	}
`

const PreviewImage = styled(Img)`
	border-radius: 50%;
	height: 100px;
	width: 100px;
	object-fit: cover;
`

const NavigationWrapper = styled.div`
	padding: 0px 20px 10px;
`


const MiniHeader = styled.div`
	font-size: 12px;
	font-weight: 500;
	&:not(:first-child) {
		margin-top: 12px;
	}
`

const Container = styled.div`
	display: flex;
	flex-direction: column;

	.app-version {
		position: absolute;
		bottom: 5px;
		right: 10px;
		font-weight: 400;
		font-size: 11px;
	}
`

const Item = styled.div`
	user-select: none;
	cursor: pointer;
	padding: 7px 4px;
	display: flex;
	align-items: center;
	transition: all 0.3s ease-in-out;

	&:hover {
		background-color: rgba(0, 0, 0, 0.075);
		border-radius: 4px;
		cursor: pointer;
	}

	&.nohovereffect {
		&:hover {
			background: transparent;
		}
	}

	.icon {
		width: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.text {
		flex: 1;
		display: flex;
		align-items: center;
		font-weight: 400;
		align-self: flex-end;
		padding-left: 5px;

		&.center {
			align-self: center;
		}
	}

	${props =>
		props.color &&
		`color: ${props.color};`};
`

const Separator = styled.div`
	border-bottom: 1px solid;
	margin: 10px 0;
`

export default withRouter(MeetingDropdown)
