import React from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'
import {Img} from 'react-image'
import ReactVisibilitySensor from 'react-visibility-sensor'

import { MainStore } from '/stores/MainStore'
import MeetingDropdown from './MeetingDropdown'
import DropdownWrapper from './DropdownWrapper'
import CustomTip from '../../../../helpers/CustomTip'
import Loader from '../../../../components/Loader'
import Grapl from '../../../../assets/Icon-SVG.svg'

const IconSvg = <img src={Grapl} alt="Grapl"/>

const is_mobile = window.matchMedia('(max-width: 767px)');

export default class TopBar extends Reflux.Component {
	constructor(props) {
		super(props)
		this.openTimeTravel = this.openTimeTravel.bind(this)
		this.mediaChange = this.mediaChange.bind(this)

		this.store = MainStore
		this.storeKeys = ['users', 'db_meeting']
		this.showSubPrompt =
			!!window['Notification'] &&
			window.Notification.permission !== 'granted' &&
			window.Notification.permission !== 'denied'

		this.state = {
			is_mobile: is_mobile.matches,
		}
	}

	mediaChange({ matches }) {
        this.setState({ is_mobile: matches });
    }

	componentDidMount() {
		is_mobile.addListener(this.mediaChange)
	}

	componentWillUnmount() {
		is_mobile.removeListener(this.mediaChange)
	}

	openTimeTravel() {
		const strWindowFeatures =
			'menubar=no,location=no,resizable=yes,scrollbars=yes,status=yes'
		window.open(
			`/time-travel/${this.props.meetingName}`,
			'Time Travel',
			strWindowFeatures
		)
	}

	render() {
		return (
			<Container className="topbar">
				<Inner>
					{
						this.state.is_mobile ? 
						<MeetingContainer
							DropdownItems={MeetingDropdown}
							setup={this.props.setup}
							noright
							width="320px"
						>
							<NameRow data-tip data-for="user-settings">
								{this.props.myUser && (
									<Avatar className="button">
										<ReactVisibilitySensor>
											<Img 
												key={Math.random()} 
												src={[this.props.myUser.avatar_url, `https://ui-avatars.com/api/?name=${this.props.myUser.username || this.props.myUser.name || ''}&format=svg&background=${this.props.myUser.color || 'random'}&color=fefefe`, 'https://grapl-storage.s3.ap-southeast-1.amazonaws.com/Grapl.svg']} 
												loader={<Loader circle containerWidth="38px" containerHeight="38px" />} 
												alt={this.props.myUser.username || this.props.myUser.name} 
												unloader={IconSvg} />
										</ReactVisibilitySensor>
									</Avatar>
								)}
							</NameRow>
						</MeetingContainer> : ''
					}
				</Inner>

				<CustomTip top={-40} left={-25} class='top-right' place='left' tip='User Profile' dataFor='user-settings' />

			</Container>
		)
	}
}

								//  <Avatar
								// 	className="button"
								// 	src={
								// 		this.props.myUser.avatar_url ||
								// 		`https://ui-avatars.com/api/?name=${this.props.myUser.name}&format=svg&background=${this.props.myUser.color || 'random'}&color=fefefe`
								// 	}
								// /> 

const Avatar = styled.div`
	overflow: visible;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url('${(props) => props.src}');
	width: 38px;
	height: 38px;
	position: relative;
	padding: 0px !important;

	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
		border-radius: 50%;
	}
`

const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: white;
	justify-content: flex-start;
`

const Inner = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	justify-content: space-between;
`

const MeetingContainer = styled(DropdownWrapper)`
	position: relative;
	display: flex;
	flex-direction: column;
	color: white;
	/* margin: 8px 0 4px 12px; */
	cursor: pointer;
	line-height: 1.4;
`

const NameRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`

const NameText = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
`
