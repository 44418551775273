{
	"name": "grapl",
	"browserslist": "> 0.5%, last 2 versions, not dead",
	"type": "module",
	"version": "1.9.83",
	"private": true,
	"dependencies": {
		"@aws-crypto/sha256-browser": "^2.0.1",
		"@aws-sdk/client-transcribe": "^3.53.0",
		"@aws-sdk/client-transcribe-streaming": "^3.53.0",
		"@aws-sdk/eventstream-marshaller": "^3.53.0",
		"@aws-sdk/util-utf8-node": "^3.52.0",
		"@babel/runtime": "^7.18.6",
		"@daily-co/daily-js": "^0.25.0",
		"@date-io/date-fns": "1.x",
		"@hot-loader/react-dom": "^16.8.6",
		"@jitsi/react-sdk": "^1.3.0",
		"@loadable/component": "^5.15.2",
		"@material-ui/core": "^4.12.3",
		"@material-ui/icons": "^4.11.3",
		"@material-ui/lab": "^4.0.0-alpha.61",
		"@material-ui/pickers": "^3.3.10",
		"@mui/lab": "^5.0.0-alpha.104",
		"@react-icons/all-files": "^4.1.0",
		"@react-spring/types": "^9.5.5",
		"@react-spring/web": "^9.5.5",
		"@reactour/tour": "^3.1.8",
		"@sentry/browser": "^7.0.0",
		"@sentry/react": "^6.16.1",
		"@sentry/tracing": "^6.16.1",
		"@swc/helpers": "^0.3.6",
		"@twilio/video-processors": "^1.0.2",
		"@twilio/video-room-monitor": "^1.0.0-beta.1",
		"@types/file-saver": "^2.0.3",
		"@types/jwt-decode": "^3.1.0",
		"@zach.codes/react-calendar": "^0.3.0",
		"animate-css-grid": "^1.4.3",
		"atob": "^2.1.2",
		"auto-launch": "^5.0.5",
		"axios": "^0.21.1",
		"babel-plugin-syntax-dynamic-import": "^6.18.0",
		"babel-plugin-wildcard": "^4.0.0",
		"babel-preset-env": "^1.6.1",
		"babel-preset-es2015": "^6.24.1",
		"babel-preset-react-app": "^3.1.1",
		"babel-preset-stage-0": "^6.24.1",
		"babel-register": "^6.26.0",
		"brace": "^0.11.1",
		"browser-pdf-to-images": "^1.0.1",
		"color": "^2.0.1",
		"compression": "^1.7.2",
		"compressorjs": "^1.0.7",
		"crypto-js": "^4.0.0",
		"date-fns": "^2.23.0",
		"date-holidays": "^1.2.0",
		"date-holidays-parser": "^3.2.2",
		"deep-copy": "^1.4.2",
		"diff-match-patch": "^1.0.0",
		"downshift": "^6.1.3",
		"draft-js": "^0.11.7",
		"draftjs-to-html": "^0.9.1",
		"dropbox": "^10.34.0",
		"electron-context-menu": "^0.9.1",
		"electron-window-state": "^4.1.1",
		"express": "^4.16.3",
		"fast-deep-equal": "^1.0.0",
		"file-saver": "^2.0.5",
		"firebase": "^9.8.4",
		"fixed-data-table": "^0.6.4",
		"hacktimer": "^1.1.3",
		"hark": "^1.1.6",
		"helmet": "^3.12.1",
		"heroku-ssl-redirect": "^0.0.4",
		"html-to-draftjs": "^1.5.0",
		"html2canvas": "^1.0.0-rc.11",
		"ignore-styles": "^5.0.1",
		"is-plain-object": "^5.0.0",
		"jsondiffpatch": "cooperka/jsondiffpatch#ba25ec9e7fb1684dfb0b5a8be3b1763bd9ab9348",
		"jspdf": "^2.3.1",
		"jszip": "^3.6.0",
		"jwt-decode": "^2.2.0",
		"konva": "^1.7.6",
		"lodash": "^4.17.21",
		"lodash.clonedeep": "^4.5.0",
		"markdown-it": "^8.4.1",
		"markdown-it-for-inline": "^0.1.1",
		"mediasoup-client": "^2.0.16",
		"mic-check": "^1.1.0",
		"microphone-stream": "^6.0.1",
		"moment": "^2.19.1",
		"moment-duration-format": "^2.2.2",
		"moment-shortformat": "^2.1.0",
		"moment-timezone": "^0.5.14",
		"msr": "^1.3.4",
		"node-libs-browser": "^2.2.1",
		"normalize-url": "1.9.1",
		"plyr": "^3.6.8",
		"pouchdb": "^7.2.2",
		"pouchdb-find": "^7.2.2",
		"prop-types": "^15.8.1",
		"query-string": "^7.1.1",
		"quill": "^1.3.7",
		"raven-js": "^3.21.0",
		"rc-tooltip": "^5.1.1",
		"react": "^16.14.0",
		"react-ace": "^5.1.0",
		"react-animations": "^1.0.0",
		"react-audio-player": "^0.17.0",
		"react-autosize-textarea": "^7.1.0",
		"react-beautiful-dnd": "^13.1.0",
		"react-big-calendar": "^0.17.0",
		"react-calendar-heatmap": "^1.6.3",
		"react-circular-progressbar": "^2.1.0",
		"react-contexify": "^5.0.0",
		"react-contextmenu": "^2.14.0",
		"react-copy-to-clipboard": "^5.0.3",
		"react-custom-scrollbars": "^4.2.1",
		"react-datepicker": "^4.6.0",
		"react-datetime": "^2.10.3",
		"react-dom": "^16.14.0",
		"react-draft-wysiwyg": "^1.14.7",
		"react-draggable": "^4.4.4",
		"react-file-drop": "^0.2.4",
		"react-flip-move": "^3.0.1",
		"react-ga": "^2.4.1",
		"react-google-calendar-api": "^2.0.3",
		"react-hot-loader": "4.8.0",
		"react-icons": "^4.3.1",
		"react-idle-timer": "^4.6.4",
		"react-image": "^4.0.3",
		"react-images": "^0.5.17",
		"react-konva": "^1.6.6",
		"react-lazyload": "^2.3.0",
		"react-loadable": "^5.3.1",
		"react-memoized-context": "^1.0.1",
		"react-mentions": "^4.3.0",
		"react-pdf": "^5.7.2",
		"react-pdf-js": "3.0.8",
		"react-pdf-pages": "^0.1.8",
		"react-player": "^2.9.0",
		"react-process-string": "^1.2.0",
		"react-quill": "^1.1.0",
		"react-resize-detector": "^7.0.0",
		"react-router": "^5.2.0",
		"react-router-dom": "^5.2.0",
		"react-s-alert": "^1.3.0",
		"react-select": "^5.4.0",
		"react-spring": "^9.3.2",
		"react-stockcharts": "^0.7.4",
		"react-table-v6": "^6.8.6",
		"react-text-mask": "^5.4.3",
		"react-toastify": "^7.0.4",
		"react-toggle-switch": "^3.0.4",
		"react-tooltip": "^3.4.0",
		"react-vis": "^1.8.2",
		"react-visibility-sensor": "^5.1.1",
		"react-youtube": "^7.5.0",
		"reactour": "^1.19.0",
		"reconnecting-websocket": "^4.4.0",
		"reflux": "^6.4.1",
		"reflux-react-16": "^1.0.2",
		"regenerator-runtime": "^0.13.9",
		"resolver": "^0.1.12",
		"rich-text": "^4.1.0",
		"rtc-screenshare": "^4.5.1",
		"scheduler": "^0.20.2",
		"screenfull": "5.1.0",
		"sentry": "^0.1.2",
		"sharedb": "^2.2.3",
		"simple-peer": "^9.10.0",
		"siriwavejs": "^2.0.2",
		"slack-markdown-it": "^1.0.5",
		"socket.io-client": "2.0.3",
		"stream-events": "^1.0.4",
		"styled-components": "^5.3.0",
		"twilio-video": "^2.20.1",
		"uifx": "^2.0.7",
		"url-regex": "3.2.0",
		"uuid": "^8.3.2",
		"valid-url": "^1.0.9"
	},
	"devDependencies": {
		"@babel/core": "^7.14.3",
		"@babel/plugin-proposal-export-default-from": "^7.14.5",
		"@babel/plugin-transform-runtime": "^7.14.3",
		"@babel/preset-env": "^7.14.2",
		"@babel/preset-react": "^7.13.13",
		"@parcel/compressor-brotli": "^2.3.2",
		"@parcel/compressor-gzip": "^2.3.2",
		"@parcel/config-default": "^2.7.0",
		"@parcel/packager-raw-url": "^2.7.0",
		"@parcel/transformer-css": "^2.7.0",
		"@parcel/transformer-raw": "^2.7.0",
		"@parcel/transformer-typescript-tsc": "^2.7.0",
		"@parcel/transformer-webmanifest": "^2.7.0",
		"@types/": "daily-co/daily-js",
		"@types/atob": "^2.1.2",
		"@types/color": "^3.0.1",
		"@types/draftjs-to-html": "^0.8.1",
		"@types/firebase": "^3.2.1",
		"@types/hark": "^1.2.1",
		"@types/html-to-draftjs": "^1.4.0",
		"@types/loadable__component": "^5.13.4",
		"@types/lodash.clonedeep": "^4.5.6",
		"@types/moment-shortformat": "^2.1.1",
		"@types/normalize-url": "^4.1.0",
		"@types/react": "^16.8.6 || ^17.0.0",
		"@types/react-animations": "^1.0.0",
		"@types/react-beautiful-dnd": "^13.1.2",
		"@types/react-custom-scrollbars": "^4.0.8",
		"@types/react-datepicker": "^3.1.8",
		"@types/react-dom": "^17.0.9",
		"@types/react-draft-wysiwyg": "^1.13.3",
		"@types/react-images": "^0.5.3",
		"@types/react-lazyload": "^3.1.0",
		"@types/react-loadable": "^5.5.4",
		"@types/react-mentions": "^4.1.3",
		"@types/react-pdf": "^5.0.9",
		"@types/react-router-dom": "^5.1.8",
		"@types/react-s-alert": "^1.3.2",
		"@types/react-select": "^5.0.1",
		"@types/react-toastify": "^4.1.0",
		"@types/react-youtube": "^7.6.2",
		"@types/reflux": "^6.4.2",
		"@types/sharedb": "^2.2.0",
		"@types/simple-peer": "^9.6.2",
		"@types/styled-components": "^5.1.9",
		"@types/twilio-video": "^2.7.3",
		"@types/url-regex": "^5.0.0",
		"@types/uuid": "^8.3.0",
		"auto-changelog": "^2.3.0",
		"autoprefixer": "^8.0.0",
		"babel-eslint": "7.1.1",
		"babel-jest": "18.0.0",
		"babel-loader": "^7.1.3",
		"babel-plugin-dynamic-import-node": "^2.3.3",
		"babel-plugin-module-resolver": "^4.1.0",
		"babel-runtime": "^6.20.0",
		"case-sensitive-paths-webpack-plugin": "1.1.4",
		"chalk": "1.1.3",
		"compression-webpack-plugin": "^1.1.8",
		"connect-history-api-fallback": "1.3.0",
		"cross-spawn": "4.0.2",
		"css-loader": "0.26.1",
		"detect-port": "1.1.0",
		"dotenv": "2.0.0",
		"electron": "^1.7.11",
		"electron-builder": "^19.55.2",
		"eslint": "3.16.1",
		"eslint-config-react-app": "^0.6.2",
		"eslint-loader": "1.6.0",
		"eslint-plugin-flowtype": "2.21.0",
		"eslint-plugin-import": "2.0.1",
		"eslint-plugin-jsx-a11y": "4.0.0",
		"eslint-plugin-react": "6.4.1",
		"extract-text-webpack-plugin": "3.0.2",
		"file-loader": "0.10.0",
		"fs-extra": "0.30.0",
		"html-webpack-plugin": "2.29.0",
		"http-proxy-middleware": "0.17.3",
		"jest": "18.1.0",
		"json-loader": "0.5.4",
		"object-assign": "4.1.1",
		"parcel": "^2.7.0",
		"postcss-loader": "1.2.2",
		"prettier": "^2.5.1",
		"promise": "7.1.1",
		"react-dev-utils": "^0.5.2",
		"simple-progress-webpack-plugin": "^1.1.1",
		"style-loader": "0.13.1",
		"svg-sprite": "^1.5.2",
		"sw-precache-webpack-plugin": "^0.11.4",
		"ts-loader": "3.x.x",
		"tslint": "^6.1.3",
		"tslint-loader": "^3.5.4",
		"typescript": "^4.2.3",
		"uglifyjs-webpack-plugin": "^1.2.2",
		"url-loader": "0.5.7",
		"user-agent-data-types": "^0.3.0",
		"webpack": "3.11.0",
		"webpack-dev-server": "2.11.1",
		"webpack-manifest-plugin": "^1.3.2",
		"whatwg-fetch": "2.0.2",
		"worker-loader": "^2.0.0"
	},
	"scripts": {
		"start": "parcel ./src/index.html --https",
		"watch": "parcel watch",
		"start:prod": "set NODE_ENV=production && parcel ./src/index.html",
		"build": "parcel build ./src/index.html && cp -rf ./well-known ./dist/.well-known",
		"build:prod": "set NODE_ENV=production && parcel build ./src/index.html",
		"build:dev": "set NODE_ENV=development && parcel build ./src/index.html",
		"version": "auto-changelog --template changelogtemp.hbs -p -u --commit-limit false && git add CHANGELOG.md",
		"analyze": "source-map-explorer 'dist/*.js' --no-border-checks ",
		"update-sprite": "svg-sprite -s --symbol-dest icons --symbol-sprite icons.svg icons/individual-icons/*.svg",
		"format": "prettier --write ./src"
	},
	"description": "Intuitive, collaborative, and reliable online meetings.",
	"author": "Isaac Nassimi",
	"license": "ISC",
	"homepage": "/",
	"eslintConfig": {
		"extends": "react-app"
	},
	"auto-changelog": {
		"commitLimit": false,
		"unreleased": true,
		"issueUrl": "",
		"replaceText": {
			"[Ff]eat:": "",
			"[Ff]eature:": "",
			"[Ss]tyle:": "",
			"[Ff]ix:": "",
			"[Oo]ther:": ""
		},
		"includeBranch": [
			"staging",
			"master"
		]
	}
}
