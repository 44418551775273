import React, { Component } from 'react';
import AddWidget from './AddWidget';
import PublicLink from './PublicLink';
import AddFromTeam from './AddFromTeam';
import Connecting from './Connecting';
import Reconnecting from './Reconnecting';
import SendVerificationEmail from './SendVerificationEmail';
import ChangeColors from './ChangeColors';
import ChangeMeetingPassword from './ChangeMeetingPassword';
import Integrations from './Integrations';
import SetSources from './SetSources';
import SetUserAvatar from './SetUserAvatar';
import SetMeetingAvatar from './SetMeetingAvatar';
import LeaveMeeting from './LeaveMeeting';
import RemoveWidget from './RemoveWidget';
import EndPresentation from './EndPresentation';
import GhostMode from './GhostMode';
import EnableMedia from './EnableMedia';
import WidgetExposure from './WidgetExposure';
import ExternalConference from './ExternalConference';
import ChangeBotSettings from './ChangeBotSettings';
import ChangeSettings from './ChangeSettings';
import VDBitmapContainer from './VDBitmapContainer';
import CreateOrJoin from './CreateOrJoin';
import AddViaEmail from './AddViaEmail';
import ClearWidget from './ClearWidget';
import PermissionNeeded from './PermissionNeeded';
import PermissionBlocked from './PermissionBlocked';
import LinksValidationDuplicate from './LinksValidationDuplicate';
import LinksValidationInvalid from './LinksValidationInvalid';
import ChangePassword from './ChangePassword';
import RenameTranscription from './RenameTranscription';
import TransferRoom from './TransferRoom';
import DeviceConnection from './DeviceConnection';
import CreateSpaceModal from './CreateSpace';
import DeleteSpace from './DeleteSpace';
import CreateRoom from './CreateRoom';
import MaximumAllowedParticipants from './MaximumAllowedParticipants';
import RemoveUser from './RemoveUser';
import RemovedUser from './RemovedUser';
import RemoveTranscription from './RemoveTranscription';
import RemoveIntegration from './RemoveIntegration';
import ConfirmLogout from './ConfirmLogout';
import UserProfile from './UserProfile';
import TaskDeleted from './TaskDeleted';
import LimitRoom from './LimitRoom';
import ChangePaymentMethod from './ChangePaymentMethod';
import UncheckPublicAndKnocking from './UncheckPublicAndKnocking'
import MeetingOngoing from './MeetingOngoing';
import ArchiveRooms from './ArchiveRooms';
import RenameMeeting from './RenameMeeting';
import TranscriptionSearch from './TranscriptionSearch';
import CancelSubscription from './SubscriptionCancel';
import EmailRegistered from './EmailRegistered';
import ConfirmGuestToAccount from './ConfirmGuestToAccount';
import SubscriptionCancelFeedback from './SubscriptionCancelFeedback';
import LimitSession from './LimitSession';
import RemoveForever from './RemoveForever';
import ChangePaymentMethodSuccess from './ChangePaymentMethodSuccess';
import LimitOrgGuest from './LimitOrgGuest';
import RecentRooms from './RecentRooms';
import Subscription from './Subscription';
import DeviceFailed from './DeviceFailed';
import SubscriptionConfirmation from './SubscriptionConfirmation';
import SubscriptionSwitchSuccess from './SubscriptionSwitchSuccess';
import UnableToShareScreen from './UnableToShareScreen';
import ConfirmRecording from './ConfirmRecording';
import ConfirmLeaveMeeting from './ConfirmLeaveMeeting';
import NoInternet from './NoInternet';
import Billings from './Billings';
import OfferProTrial from './OfferProTrial';
import ArchivedRoom from './ArchivedRoom';
import SharedSpaceCreate from './SharedSpaceCreate';
import SharedSpaceCreateRoomInRoot from './SharedSpaceCreateRoomInRoot';
import SharedSpaceCreateFolder from './SharedSpaceCreateFolder';
import SharedSpaceCreateRoomInFolder from './SharedSpaceCreateRoomInFolder';
import SharedSpaceRenameFolder from './SharedSpaceRenameFolder';
import SharedSpaceRename from './SharedSpaceRename';
import ManageSharedUsers from './ManageSharedUsers';
import SharedSpaceRemoveFolder from './SharedSpaceRemoveFolder';
import SharedSpaceDelete from './SharedSpaceRemoveSpace';
import SharedSpaceRemoveUser from './SharedSpaceRemoveUser';
import ErrorEmailInvitation from './ErrorEmailInvitation';
import TourStepsList from './TourStepsList';


export default class Modals extends Component {
    render() {
        switch (this.props.currentModal) {
            case 'Connecting':
                return <Connecting {...this.props} />;
            case 'AddWidget':
                return <AddWidget {...this.props} />;
            case 'Reconnecting':
                return <Reconnecting {...this.props} />;
            case 'PublicLink':
                return <PublicLink {...this.props} />;
            case 'AddFromTeam':
                return <AddFromTeam {...this.props} />;
            case 'AddViaEmail':
                return <AddViaEmail {...this.props} />;
            case 'SendVerificationEmail':
                return <SendVerificationEmail {...this.props} />;
            case 'ChangeColors':
                return <ChangeColors {...this.props} />;
            case 'Integrations':
                return <Integrations {...this.props} />;
            case 'ChangeMeetingPassword':
                return <ChangeMeetingPassword {...this.props} />;
            case 'SetSources':
                return <SetSources {...this.props} />;
            case 'SetUserAvatar':
                return <SetUserAvatar {...this.props} />;
            case 'ChangeBotSettings':
                return <ChangeBotSettings {...this.props} />;
            case 'ChangeSettings':
                return <ChangeSettings {...this.props} />;
            case 'VDBitmapContainer':
                return <VDBitmapContainer {...this.props} />;
            case 'SetMeetingAvatar':
                return <SetMeetingAvatar {...this.props} />;
            case 'LeaveMeeting':
                return <LeaveMeeting {...this.props} />;
            case 'RemoveWidget':
                return <RemoveWidget {...this.props} />;
            case 'ClearWidget':
                return <ClearWidget {...this.props} />;
            case 'EndPresentation':
                return <EndPresentation {...this.props} />;
            case 'GhostMode':
                return <GhostMode {...this.props} />;
            case 'EnableMedia':
                return <EnableMedia {...this.props} />;
            case 'WidgetExposure':
                return <WidgetExposure {...this.props} />;
            case 'ExternalConference':
                return <ExternalConference {...this.props} />;
            case 'create-or-join':
                return <CreateOrJoin {...this.props} />;
            case 'PermissionNeeded':
                return <PermissionNeeded {...this.props} />;
            case 'PermissionBlocked':
                return <PermissionBlocked {...this.props} />;
            case 'DeviceConnection':
                return <DeviceConnection {...this.props} />;
            case 'DeviceFailed':
                return <DeviceFailed {...this.props} />;
            case 'TransferRoom':
                return <TransferRoom {...this.props} />;
            case 'LinksValidationDuplicate':
                return <LinksValidationDuplicate {...this.props} />;
            case 'LinksValidationInvalid':
                return <LinksValidationInvalid {...this.props} />;
            case 'ChangePassword':
                return <ChangePassword {...this.props} />;
            case 'RenameTranscription':
                return <RenameTranscription {...this.props} />;
            case 'CreateSpace':
                return <CreateSpaceModal {...this.props} />;
            case 'DeleteSpace':
                return <DeleteSpace {...this.props} />;
            case 'CreateRoomInSpace':
                return <CreateRoom {...this.props} />;
            case 'MaximumAllowedParticipants':
                return <MaximumAllowedParticipants {...this.props} />;
            case 'RemoveUser':
                return <RemoveUser {...this.props} />;
            case 'RemovedUser':
                return <RemovedUser {...this.props} />;
            case 'ArchivedRoom':
                return <ArchivedRoom {...this.props} />;
            case 'TaskDeleted':
                return <TaskDeleted {...this.props} />;
            case 'RemoveTranscription':
                return <RemoveTranscription {...this.props} />;
            case 'RemoveIntegration':
                return <RemoveIntegration {...this.props} />;
            case 'ConfirmLogout':
                return <ConfirmLogout {...this.props} />;
            case 'Subscription':
                return <Subscription {...this.props} />;
            case 'Billings':
                return <Billings {...this.props} />;
            case 'UserProfile':
                return <UserProfile {...this.props} />;
            case 'LimitRoom':
                return <LimitRoom {...this.props} />;
            case 'LimitSession':
                return <LimitSession {...this.props} />;
            case 'ChangePaymentMethod':
                return <ChangePaymentMethod {...this.props} />;
            case 'UncheckPublicAndKnocking':
                return <UncheckPublicAndKnocking {...this.props} />;
            case 'MeetingOngoing':
                return <MeetingOngoing {...this.props} />
            case 'ArchiveRooms':
                return <ArchiveRooms {...this.props} />
            case 'RenameRoom':
                return <RenameMeeting {...this.props} />
            case 'TranscriptionSearch':
                return <TranscriptionSearch {...this.props} />
            case 'CancelSubscription':
                return <CancelSubscription {...this.props} />
            case 'EmailRegistered':
                return <EmailRegistered {...this.props} />
            case 'ConfirmGuestToAccount':
                return <ConfirmGuestToAccount {...this.props} />;
            case 'SubscriptionCancelFeedback':
                return <SubscriptionCancelFeedback {...this.props} />;
            case 'RemoveForever':
                return <RemoveForever {...this.props} />;
            case 'ChangePaymentMethodSuccess':
                return <ChangePaymentMethodSuccess {...this.props} />;
            case 'LimitOrgGuest':
                return <LimitOrgGuest {...this.props} />;
            case 'RecentRooms':
                return <RecentRooms {...this.props} />;
            case 'SubscriptionConfirmation':
                return <SubscriptionConfirmation {...this.props} />
            case 'SubscriptionSwitchSuccess':
                return <SubscriptionSwitchSuccess {...this.props} />
            case 'UnableToShareScreen':
                return <UnableToShareScreen {...this.props} />
            case 'ConfirmRecording':
                return <ConfirmRecording {...this.props} />;
            case 'ConfirmLeaveMeeting':
                return <ConfirmLeaveMeeting {...this.props} />;
            case 'NoInternet':
                return <NoInternet {...this.props} />;
            case 'OfferProTrial':
                return <OfferProTrial {...this.props} />;
            case 'CreateSharedSpace':
                return <SharedSpaceCreate {...this.props} />;
            case 'SharedSpaceCreateRoomInRoot':
                return <SharedSpaceCreateRoomInRoot {...this.props} />;
            case 'SharedSpaceCreateFolder':
                return <SharedSpaceCreateFolder {...this.props} />;
            case 'SharedSpaceCreateRoomInFolder':
                return <SharedSpaceCreateRoomInFolder {...this.props} />;
            case 'SharedSpaceRenameFolder':
                return <SharedSpaceRenameFolder {...this.props} />;
            case 'SharedSpaceRename':
                return <SharedSpaceRename {...this.props} />;
            case 'SharedManageUsers':
                return <ManageSharedUsers {...this.props} />;
            case 'SharedSpaceRemoveFolder':
                return <SharedSpaceRemoveFolder {...this.props} />;
            case 'SharedSpaceDelete':
                return <SharedSpaceDelete {...this.props} />;
            case 'SharedSpaceRemoveUser':
                return <SharedSpaceRemoveUser {...this.props} />;
            case 'ErrorEmailInvitation':
                return <ErrorEmailInvitation {...this.props} />;
            case 'TourStepsList':
                return <TourStepsList {...this.props} />;
            default:
                if (this.props.currentModal !== null) {
                    console.warn(`Couldn't find modal: ${this.props.currentModal}`);
                }

                return null;
        }
    }
}
