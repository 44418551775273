import React, { PureComponent, Fragment } from 'react'
import Reflux from 'reflux'
import styled from 'styled-components'
import { CallingInstanceState } from '../../../calling/types'
import Dropdown from '../../../components/Dropdown'
import { isJSON } from '../../../helpers'
import { AuthStore } from '../../../stores/AuthStore'
import { ModalStore, ModalActions } from '../../../stores/ModalStore'
import { VariableCallingStore } from '../../../stores/VariableCallingStore'
import { OrganizationWrapper } from '../styles'

import LoopApi from '/helpers/LoopApi'

export default class CreateOrJoin extends Reflux.PureComponent {
	constructor(props) {
		super(props)

		this.stores = [VariableCallingStore, ModalStore, AuthStore]
		this.storeKeys = ['status', 'cb', 'isJoin', 'jwt']
		this.state = {
			create: false,
			meetingName: '',
			errorText: null,
			status: '',
			cb: () => {},
			selectedOrg: null,
		}
		this.toggleCreate = this.toggleCreate.bind(this)
		this.updateValue = this.updateValue.bind(this)
		this.onSubmit = this.onSubmit.bind(this)
	}

	componentDidMount() {
		const selOrg = localStorage.getItem('selected_organization')
		const parsedSelOrg = isJSON(selOrg) ? JSON.parse(selOrg) : {}
		const orgs = this.state?.jwt?.data?.organizations || []
		const lorg = parsedSelOrg?.[this.state?.jwt?.data?._id] || null

		let sOrg = null

		if(lorg === 'All' && orgs.length > 0 || lorg === null) {
			sOrg = orgs[0]?.organization?._id || null
		} else if(lorg === 'personal' ){
			sOrg = 'personal'
		} else {
			sOrg = lorg
		}
		this.setState({ create: window.location.hash === '#create' ? true : !!!this.state.isJoin, selectedOrg: sOrg })
		window.location.hash = ''
        // mimic the browser's behavior when forwarding so the component of meeting will remount
				// 	this.props.history.listen(location => {
				// 			if (this.props.history.action === 'POP') {
				// 					window.location.reload()
				// 			}
				// 	});
	}

	toggleCreate() {
		this.setState({ create: !this.state.create, errorText: null })
	}

	updateValue(e) {
		let meetingName = e.target.value

		if (this.state.create && meetingName.length > 20) return this.setState({ errorText: 'Room name should not exceed more than 20 characters' })
		this.setState({
			meetingName: meetingName,
			errorText: ''
		})
	}

	async onSubmit(e) {
		e.preventDefault()
		this.setState({ errorText: null })
		try {
			let meetingName = this.state.meetingName

			var validUrl = require('valid-url');
			const valid = await validUrl.isUri(this.state.meetingName);
			if(valid && !!!this.state.create) {
				const urlArr = (this.state.meetingName || '').trim().split('/')
				meetingName = urlArr && urlArr.length > 0 ? urlArr[3] : this.state.meetingName
			}

			if(this.state.create) {
				meetingName = `${meetingName}`.trim()
			}


			if(meetingName.length > 24) return this.setState({ errorText: 'Room name should not exceed more than 24 characters' })

			const { exists, hasMaxParticipants } = await LoopApi(null, 'CheckIfMeetingIsPublic', {}, [
				['name', meetingName],
			])

            if(hasMaxParticipants) return this.setState({ errorText: 'This room has reached participant limit.' })

            let room = "";
			if (this.state.create && exists) {
				return this.setState({ errorText: 'This room name is taken' })
			} else if (!this.state.create && !exists) {
				return this.setState({
					errorText: `This room doesn't seem to exist`,
				})
			} else if (this.state.create) {
				room = await LoopApi(null, 'CreateMeeting', {
					name: `${this.state.meetingName}`.trim(),
					is_public: true,
                    selectedOrg: this.state.selectedOrg !== 'personal'  && this.state.selectedOrg?.toLowerCase() !== 'all' ? this.state.selectedOrg : null
				})
			}

			if(room?.error) {
				return this.setState({ errorText: room?.msg || 'Something went wrong' })
			}

			this.props.closeModal && this.props.closeModal()

			if(this.state.status === CallingInstanceState.UninitializedWithoutToken) {
				if(room === '') {
					room = {
						meeting: { name: meetingName }
					}
				}
				this.props.history.push(`/${room.meeting.name}`)
				!this.props.skip_reload && window.location.reload()
			} else {
				this.state.cb()
			}

		} catch (e) {
			this.setState({ errorText: e })
			const hasOrg = this.state.selectedOrg !== 'personal'  && this.state.selectedOrg?.toLowerCase() !== 'all' ? this.state.selectedOrg : null
            ModalActions.SetModal('LimitRoom', { hasOrg })
		}
	}

	handleOrgChange = (item, name) => {
        this.setState((prevState) => ({...prevState, selectedOrg: item.value}))
    }

	render() {
		let switchText = 'Create a room'
		if (this.state.create && this.state.errorText) {
			switchText = 'Click here to join this room'
		} else if (this.state.create) {
			switchText = 'Join a room'
		} else if (this.state.errorText) {
			switchText = 'Click here to create this room'
		}

		const savedorgs = this.state.jwt && this.state.jwt.data && this.state.jwt.data.organizations ? this.state.jwt.data.organizations : []
		const uniqueIds = [];
		const orgs = savedorgs.filter((o) => {
			const isDuplicate = uniqueIds.includes(o?.organization?._id);
			if (!isDuplicate) {
				uniqueIds.push(o?.organization?._id);
			
				return true;
			}
			return false;
		})

		const Organization = () => {
			//console.log(GlobalState.auth)
			if(!Array.isArray(orgs)) return <React.Fragment></React.Fragment>
			if(Array.isArray(orgs) && orgs.length === 0) return <React.Fragment></React.Fragment>
			if(!orgs[0]?.organization?._id) return <></>;
	
			let ORGANIZATIONS = []
	
			if(this.state.jwt?.data?.role === 'user') {
				ORGANIZATIONS = [
					...ORGANIZATIONS,
					{
						name: "My personal rooms",
						value: "personal"
					}
				]
			}
	
			ORGANIZATIONS = [
				...ORGANIZATIONS,
				...orgs.map((o) => ({
					name:o.organization?.name,
					value: o.organization?._id
				}))
			]
			
			return (
			  <OrganizationWrapper style={{ width: '100%' }} className="withBorder">
				<Dropdown
					name="selectedOrg"
					value={this.state.selectedOrg}
					items={ORGANIZATIONS}
					// inputWidth={120}
					onChange={this.handleOrgChange}
				/>
			  </OrganizationWrapper>
			)
		}

		return (
			<Container className="topbar-modal" data-tour="tour4-manageroom">
				<Title className="header">{this.state.create ? 'Create' : 'Join'} Room</Title>
				{this.state.create &&
					<Subtitle className="subheader">
						<Fragment>
							Create a name that describes the room <br /> activity or content
						</Fragment>
					</Subtitle>
				}
				<NameForm onSubmit={this.onSubmit}>
					{
						this.state.create ?
						<React.Fragment>
						{Array.isArray(orgs) && orgs.length > 0 && <div style={{
							textAlign: 'left',
							marginBottom: '10px',
							fontSize: '12px'
						}}>Room will be saved under:</div>}
						<Organization />
						</React.Fragment> : ''
					}
					<Input
						className="border-light topbar"
						type="text"
						value={this.state.meetingName}
						onChange={this.updateValue}
						placeholder={this.state.create ? 'room to create' : 'room id'}
						autoFocus
					/>
					<SubmitButton
						className="button primary"
						type="submit"
						value={this.state.create ? 'Create' : 'Enter Room'}
						disabled={this.state.meetingName.trim().length === 0}
					/>
					{this.state.errorText && (
						<ErrorText className="error">{this.state.errorText}</ErrorText>
					)}
					<SwitchLink
						className={this.state.status !== CallingInstanceState.UninitializedWithoutToken && this.state.status !== CallingInstanceState.Disconnected ? 'disabled' : ''}
						onClick={this.state.status !== CallingInstanceState.UninitializedWithoutToken && this.state.status !== CallingInstanceState.Disconnected ? () => {} : this.toggleCreate}
						title={this.state.status !== CallingInstanceState.UninitializedWithoutToken && this.state.status !== CallingInstanceState.Disconnected ? 'Not allowed. Meeting is ongoing.' : ''}
					>
						{switchText}
					</SwitchLink>
				</NameForm>
			</Container>
		)
	}
}

const ErrorText = styled.div`
	margin-top: 15px;
	text-align: center;
`
const Subtitle = styled.div`
	margin-bottom: 30px;
	text-align: center;
`

const SwitchLink = styled.div`
	text-decoration: underline;
	text-align: center;
	cursor: pointer;
	margin-top: 30px;
	font-weight: 500;

	&.disabled {
		cursor: default;
		opacity: 0.5;
	}
`

const SubmitButton = styled.input`
	height: 36px;
	border: none;
	padding: 0px !important;
	margin-top: 12px;
	font-size: 14px;
	cursor: pointer;
	transition: box-shadow 0.3s ease-out;

	&:disabled {
		pointer-events: none;
		opacity: .5;
	}

	&:hover {
		box-shadow: ${props => props.theme.shadows.light};
	}
`

const NameForm = styled.form`
	display: flex;
	align-items: stretch;
	flex-direction: column;
`

const Title = styled.div`
	text-align: center;
	font-weight: 500;
	font-size: 16px;
	margin-bottom: 20px;
`

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 36px;
	border-radius: 4px;
	box-shadow: ${props => props.theme.shadows.newlight};
	width: 100%;
	z-index: 3;

	@media (min-width: 423px) {
		width: 352px;
	}
`

const Input = styled.input`
	padding: 8px !important;
	border-radius: 32px !important;
	border: 1px solid;
	font-size: 16px !important;
	background: none;
`
